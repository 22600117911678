<template>
  <div class="content">
    <!--[main]-->
    <div class="main">
      <PageHeader :pageName="pageName" :pageNavs="pageNavs" />
      <section class="section">
        <div class="row">
          <div class="col">
            <Panel type="sheet">
              <template v-slot:headline>ユーザー登録</template>
              <template v-slot:body>
                <transition
                  name="fadeHeight"
                  mode="out-in"
                  @beforeLeave="beforeLeave"
                  @enter="enter"
                  @afterEnter="afterEnter"
                >
                  <router-view ref="registerChildComp" @footer-show="footerShow" @footer-hide="footerHide" />
                </transition>
              </template>
            </Panel>
          </div>
        </div>
      </section>
    </div>
    <!--[/main]-->

    <!--[footer]-->
    <PageFooter v-if="footer">
      <template v-slot:footer>
        <ul class="listGrid">
          <li class="listGrid-item">
            <router-link class="btn btn-white" :to="{ name: 'SettingUserRegisterSelect' }">
              <i class="aikon aikon-arrow_left"></i>
              アカウントを選択し直す
            </router-link>
          </li>
          <li class="listGrid-item pos-end">
            <ActionButton class="btn btn-blue" :handle-submit="register" button-text="登録する" />
          </li>
        </ul>
      </template>
    </PageFooter>
    <!--[/footer]-->
  </div>
</template>

<script>
import nav from '@/mixins/nav/setting';
import fadeHeight from '@/mixins/plugin/fadeHeight';

export default {
  name: 'SettingUserRegister',
  data: function() {
    return {
      pageName: '設定',
      footer: false,
    };
  },
  mixins: [nav, fadeHeight],
  methods: {
    footerShow: function() {
      this.footer = true;
    },
    footerHide: function() {
      this.footer = false;
    },
    register: function() {
      this.$refs.registerChildComp.register();
    },
  },
};
</script>
